import ImageFallback from "Components/image_fallback";

const DesktopSmall = ({ ...props }) => {

	const prop = props;

	return (
		<div className="card-small">
			{prop.isImage && (
				<a href={prop.link} className="article-image-container">
					<ImageFallback
						src={prop.image}
						width={prop.width || 100}
						height={prop.height || 100}
						quality={70}
						objectFit="cover"
						layout="fixed"
						alt="article"
						fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
					/>
				</a>
			)}
			<div className="section-right">
				<a href={prop.catSlug} className="tag">
					{prop.category}
				</a>
				<a href={prop.link} className="title">{prop.title}</a>
				<div className="author">
					<a href={prop.authorSlug} className="author-image-container">
						<ImageFallback
							src={prop.authorImage}
							alt="author"
							layout="fixed"
							objectFit="cover"
							width={17}
							height={17}
							quality={70}
							fallbackSrc={process.env.NEXT_PUBLIC_IMAGE_NO_USER}
						/>
					</a>
					<p className="author-name">
						<a href={prop.authorSlug}>
							{prop.authorName}
						</a>
						{ prop.isDate ? `・${prop.date}` : ""}
					</p>
				</div>
			</div>

			<style jsx>
				{`
          .section-right {
            display: flex;
            gap: 16px;
            flex-flow: column;
						justify-content: space-between;
            gap: ${!prop.isImage ? "0.5rem" : "0rem"};
          }
          .card-small {
            display: flex;
            flex-flow: row;
            gap: 16px;
						width: 100%
          }
          .author {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 12px;
          }
          .article-image-container {
            width: ${`${prop.width}px` || "100px"};
            height: ${`${prop.height}px` || "100px"};
            flex-shrink: 0;
            transition: .5s ease;
          }
          .article-image-container:hover {
            opacity: 0.5;
          }
          .image-article {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #E66983;
            text-transform: uppercase;
            // margin: 0 0 8px 0;
            transition: .5s ease;
          }
          .tag:hover {
            color: #DB264D;
          }
          .title {
            font-family: Poppins;
            font-size: ${prop.fontSize ? prop.fontSize : "18px"};
            line-height: 28px;
            font-weight: 600;
            letter-spacing: 0em;
            text-align: left;
            color: #1A1E23;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            transition: .5s ease;
          }
          .title:hover {
            color: #DB264D;
          }
          .author-image-container {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            overflow: hidden;
          }
          .author-name {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #919EAB;
            text-transform: uppercase;
            margin: 0;
          }

          .author-name a {
            color: #919EAB;
            transition: .5s ease;
          }

          .author-name a:hover {
            color: #DB264D;
          }
        `}
			</style>
		</div>
	);

};

export default DesktopSmall;
